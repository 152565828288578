import React from "react";
import TimelineItem from "./TimeLineItem";
import Section from "./Section";

const AboutRoadmap = () => (
  <div className="section-block replicable-content bkg-grey-ultralight">
    <div className="row flex one-column-on-tablet">
      <Section
        title="Short-term"
        lead="메타버스 기반 가상자산 커뮤니티 운영"
        description="가상자산에 관해 얘기할 수 있는 메타버스 기반의 커뮤니티를 운영하여 메타버스 운영 노하우를 쌓고 부가기능들을 출시합니다."
      />
      <div className="column width-4">
        <ul className="timeline left box rounded xlarge bkg-white">
          <TimelineItem title="흑우촌 시작" date="2023년 1분기" isSection />
          <TimelineItem title="흑우촌 메타버스 오픈베타 시작" date="2024년 12월" />
          <TimelineItem title="흑우촌 노드 정식운영 시작" date="2025년 1분기" />
          <TimelineItem title="디지털 에셋 스토어 제휴" date="2025년 2분기" />
          <TimelineItem title="차세대 메타버스 플랫폼 개발 시작" date="2025년 3분기" isLeft />
          <TimelineItem title="차세대 플랫폼 출시 및 이주" date="2027년 1분기" isSection />
        </ul>
      </div>
      <Section
        title="Long-term"
        lead="높은 몰입감의 메타버스 플랫폼 출시"
        description="흑우촌 메타버스 운영 노하우를 바탕으로, Unreal Engine 5 기반의 메타버스 플랫폼을 출시합니다. VR 헤드셋과 언리얼엔진의 강력한 그래픽으로 색다른 몰입감을 주는 것이 목표입니다."
      />
    </div>
  </div>
);

export default AboutRoadmap;
